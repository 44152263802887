import React from 'react';
import './homepage.css';
// import pizza1 from '../../assets/pizza1.png'
import { useTranslation } from 'react-i18next';
import menu from '../../assets/menu-website.pdf';
// import horno from '../../assets/horno.jpeg'
// import san from '../../assets/san_juan.jpeg';
const HomePage = () => {
  const { t } = useTranslation();

  return (
    <>
    
    <div className="homepage">
    {/* <img src={horno} className='homepage-horno' alt="pizza representation"/> */}
    
      <div className="header">


      <h1 className='cssanimation'><span className=' cssanimation fadeInTop'>REGINA</span> <span className='cssanimation fadeInBottom'>MARGHERITA</span></h1>
      <p>{t('HomePage.ristorante')}</p>
      <p>{t('HomePage.autentica')}</p>

        <a className="cta-button" href={"/carta.pdf"} target="_blank" rel="noreferrer">{t('HomePage.buttonsd')}</a>
        {/* <img src={pizza1} className='homepage-pizza1' alt="pizza representation"/> */}
        </div>
      </div>

    </>
  );
}

export default HomePage;
